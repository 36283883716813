.menu {
  min-width: 80px;
  overflow-y: auto;

  .item {
    padding: 0.5rem 0 0.5rem 1rem;
    font-weight: 400;
    font-size: 0.875rem;
    color: black;
    border-top: solid 1px rgb(235, 235, 235);
    border-bottom: solid 1px rgb(235, 235, 235);

    &:hover {
      background-color: rgb(235, 235, 235);
    }
  }
}
