@import './custom/react-select.scss';

* {
  box-sizing: border-box;
}

.ReactModal__Overlay {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4) !important;
}

// duik input
input.form-control {
  width: 100%;
  height: 1.9rem;
  padding: 4px 6px;
}

// duik dropdown
button.dropdown-toggle {
  height: 1.9rem;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  &:before,
  &:after {
    top: 0.56rem;
  }
}

.dropdown-menu {
  max-height: 480px;
}

// duik form element label
.form-group label {
  font-size: 0.7rem;
}

// duik scroll area
.scroll-area {
  padding-right: 1rem;
}

.btn:disabled {
  color: white;
}
