@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

.js-admin-modal {
  padding: 1rem;
  letter-spacing: 0.06rem;
  width: 840px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  .header {
    @include flex-row-space-between;
    align-items: center;
    margin: 0rem 1rem 0.6rem;

    .header-title {
      font-size: 1.4rem !important;
      font-weight: 600;
    }
  }
  .header-icon {
    margin: 0;
  }
  .content {
    .header-inputs {
      margin: 1rem 2rem 2rem;

      & > div + div {
        margin-top: 1rem;
      }

      .group {
        @include flex-row-space-between;

        & > div {
          width: 50%;
        }

        & > div + div {
          margin: 0 0 0 1rem;
        }

        @media screen and (max-width: 768px) {
          flex-direction: column;

          & > div {
            width: 100%;
          }

          & > div + div {
            margin: 1rem 0 0 0;
          }
        }
      }
    }
    .title-section {
      margin: 1rem 2rem;
      @include flex-row-space-between;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .titles {
        @include flex-col-space-between;
        width: 50%;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        .title {
          @include flex-row-space-between;
          & > div {
            margin: 0;
            flex: 1;
          }

          & > div + div {
            margin-left: 0.4rem;
          }
        }
      }
      .avatar {
        width: 50%;
        @include flex-col-center;

        @media screen and (max-width: 768px) {
          margin-top: 2rem;
          width: 100%;
        }

        button {
          margin-top: 1rem;
        }
      }
      .icon {
        width: 30%;
        @include flex-center;
        margin-top: 2rem;
        margin-right: 4rem;
        width: 8rem;
        height: 8rem;
        border-radius: 4rem;
      }
    }
    .mgmt-section {
      @include flex-row-space-between;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      margin: 0 2rem;
      & > div + div {
        margin-left: 8%;
      }
      .mgmt-inputs {
        width: 46%;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
      .mgmt-roles {
        width: 46%;
        @include flex-col-space-between;

        @media screen and (max-width: 768px) {
          width: 100%;
          margin-top: 2rem;
          align-items: center;
        }
      }
    }
    .desc-section {
      margin: 2rem;
      .desc-title {
        color: $tar-grey2;
        font-size: 0.8rem;
      }
      .description {
        text-align: center;
        font-size: 0.9rem;
        margin: 1rem 0;
      }
    }
    .years-section {
      margin: 1rem 5rem;
      @include flex-row-center;

      & > div + div {
        margin-left: 4rem;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-left: 2rem;
        margin-right: 2rem;

        & > div + div {
          margin-left: 0rem;
        }
      }
    }
    .props-section {
      margin: 2rem;

      @include flex-row-space-around;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .column {
        width: 30%;
        @include flex-col-space-around;

        @media screen and (max-width: 768px) {
          align-items: center;
          width: 100%;
        }

        .title {
          font-weight: 500;
          font-size: 0.9rem;
          margin-bottom: 0.6rem;
        }
        .column-text {
          font-size: 0.7rem;
          margin: 0.2rem;
        }
      }
    }
  }
  .footer {
    @include flex-row-center;
    margin-bottom: 1rem;

    & > button {
      margin: 0 1rem;
      color: white;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;

      & > button + button {
        margin-top: 1rem;
      }
    }

    .update {
      background-color: $tar-green1;
    }
    .builder {
      background-color: $tar-blue1;
    }
    .view {
      background-color: $tar-yellow1;
    }
  }
  .select-error {
    color: red;
    margin: 0.3rem 0;
    font-size: 0.9rem;
  }
}
