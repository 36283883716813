@import '@duik/core/_vars.scss';
@import '../../../../../assets/scss/variables.scss';
@import '../../../../../assets/scss/mixins.scss';

.reference-details {
  margin: 1rem 0 2rem 0;
  padding: 0.6rem 1rem;
  border: 1px solid $border-color-highlight;
  border-radius: 0.2rem;

  .section1 {
    @include flex-row-wrap;
    justify-content: space-between;

    & > div {
      width: 30%;

      @media screen and (max-width: 992px) {
        width: 48%;
      }

      @media screen and (max-width: 576px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 576px) {
      flex-direction: column;
      width: 100%;
    }

    .location {
      .label {
        @include input-group-label;
        margin-bottom: 0.6rem;
      }
      .inputs {
        @include flex-row-space-between;

        & > div {
          margin: 0;
        }

        & > div + div {
          margin-left: 1rem;
        }

        @media screen and (max-width: 576px) {
          flex-direction: column;
          width: 100%;

          & > div + div {
            margin-left: 0;
            margin-top: 1rem;
          }
        }
      }
    }

    .buttons {
      width: 100%;
      @include flex-row-center;
      margin-top: 2rem;

      & > button + button {
        margin-left: 1rem;
      }

      .update {
        background-color: $cyan;
        color: white;
      }
      .remove {
        background-color: $red;
        color: white;
      }

      @media screen and (max-width: 576px) {
        flex-direction: column;

        & > button + button {
          margin-left: 0;
          margin-top: 1rem;
        }
      }
    }
  }
}
