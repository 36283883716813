.main-modal {
  position: absolute;
  width: 1120px;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;

  & > div {
    margin: 0 auto;
  }

  @media screen and (max-width: 1200px) {
    width: 768px;
  }

  @media screen and (max-width: 768px) {
    width: 600px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.second-modal {
  position: absolute;
  width: 720px;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;

  & > div {
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    width: 600px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
}
