@import "../../assets/scss/variables.scss";

.slider {
  width: 100%;
  margin: 1rem 0;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &-title,
  &-display {
    font-size: 0.8rem;
    font-weight: 500;
  }
  &-bar {
    width: 100%;
  }
  input {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 0.3rem;
      background: $tar-green2;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 0.3rem;
      background: $tar-green2;
      cursor: pointer;
    }
  }
}
