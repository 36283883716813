@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

.jp-candidate-search-modal {
  padding: 1rem;
  letter-spacing: 0.06rem;
  width: 960px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  .header {
    @include flex-row-space-between;
    align-items: center;
    margin: 0.2rem 1rem 0.6rem 2rem;
  }
  .header-title {
    font-size: 1.6rem !important;
    font-weight: 600;
  }
  .header-info {
    font-size: 0.9rem;
    line-height: 1rem;
  }
  .header-icon {
    margin: 0;
  }
  .content {
    .title-section {
      @include flex-row-space-between;
      margin: 1rem 2rem;
      & > div {
        flex: 1;
      }
      .titles {
        @include flex-col-space-between;
        .title {
          font-size: 1.2rem;
          font-weight: 500;
          margin: 0.3rem 0;
        }
      }
      .icon {
        @include flex-center;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        .icon {
          margin-top: 1rem;
        }
      }
    }
    .desc-section {
      margin: 2rem;
      .desc-title {
        color: $tar-grey2;
        font-size: 0.8rem;
      }
      .description {
        text-align: center;
        font-size: 0.9rem;
        margin: 1rem 0;
      }
    }
    .years-section {
      margin: 1rem 6rem;
      @include flex-row-space-between;
      .years {
        font-size: 1.1rem;
        font-weight: 600;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        .years {
          text-align: center;
        }

        & > .years + .years {
          margin-top: 1rem;
        }
      }
    }
    .props-section {
      margin: 2rem 0;
      @include flex-row-space-around;
      .column {
        flex: 1;
        text-align: center;
        .column-title {
          font-weight: 600;
          margin-bottom: 0.6rem;
        }
        .column-text {
          font-size: 0.8rem;
          margin: 0.2rem;
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        & > .column + .column {
          margin-top: 1rem;
        }
      }
    }
  }
  .footer {
    @include flex-row-center;
    margin-bottom: 1rem;
    & > button {
      margin: 0 1rem;
      color: white;
    }
    .resume {
      background-color: $tar-green1;
    }
    .interview {
      background-color: $tar-blue1;
    }
    .view {
      background-color: $tar-yellow1;
    }
    .hire {
      background-color: $tar-green4;
    }

    @media screen and (max-width: 576px) {
      flex-direction: column;

      & > button + button {
        margin-top: 1rem;
      }
    }
  }
}
