@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

.js-full-job-listing-modal {
  padding: 1rem;
  letter-spacing: 0.06rem;
  width: 800px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  .header {
    @include flex-row-space-between;
    align-items: center;
    margin: 0.6rem 1rem;
  }
  .header-title {
    font-size: 1.6rem !important;
    font-weight: 600;
  }
  .header-icon {
    margin: 0;
  }
  .content {
    .title-section {
      @include flex-row-space-between;
      margin: 1rem 0.6rem 1rem 1rem;
      .titles {
        @include flex-col-space-between;
        width: 40%;
        .title {
          font-size: 1.1rem;
          font-weight: 600;
          margin: 0.3rem 0;
        }
      }
      .desc-section {
        width: 50%;
        margin: 0;
        .desc-title {
          color: $tar-grey2;
          font-size: 0.8rem;
        }
        .description {
          font-size: 0.9rem;
          margin: 1rem 0;
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        .titles {
          width: 100%;
        }

        .desc-section {
          width: 100%;
          margin-top: 1rem;
        }
      }
    }
    .years-section {
      margin: 2rem 3rem;
      @include flex-row-space-between;
      .years {
        font-size: 1.1rem;
        font-weight: 600;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        .years {
          text-align: center;
        }

        & > .years + .years {
          margin-top: 1rem;
        }
      }
    }
    .props-section {
      margin: 2rem;
      @include flex-row-space-around;
      .props-column {
        width: 30%;
        &-title {
          font-weight: 600;
          margin-bottom: 0.6rem;
        }
        &-text {
          font-size: 0.8rem;
          margin: 0.2rem;
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        .props-column {
          width: 100%;
          text-align: center;
        }

        & > .props-column + .props-column {
          margin-top: 1rem;
        }
      }
    }
    .details-section {
      margin: 1rem 2rem;
      @include flex-row-wrap;
      justify-content: space-between;
      .details-column {
        margin: 1rem 0;
        width: 46%;
        &-title {
          font-size: 0.9rem;
          font-weight: 600;
          margin-bottom: 0.6rem;
        }
        &-text {
          font-size: 0.8rem;
          margin: 0.2rem;
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        .details-column {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  .footer {
    .buttons {
      width: 100%;
      @include flex-row-center;
      margin-bottom: 1rem;
      & > button {
        margin: 0 1rem;
        color: white;
      }
      .apply {
        background-color: $tar-green1;
      }
      .decline {
        background-color: $tar-red2;
      }
      .view {
        background-color: $tar-yellow1;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        & > button + button {
          margin-top: 1rem;
        }
      }
    }
    .status {
      display: flex;
      align-items: center;
      text-transform: capitalize;
      margin-right: 1rem;
      font-weight: 500;
    }
    .applied {
      color: $tar-blue1;
    }
    .hired {
      color: $tar-green4;
    }
    .declined {
      color: $tar-yellow1;
    }
    .rejected {
      color: $tar-red1;
    }
    .apply-error {
      font-size: 0.875rem;
      font-weight: 400;
      color: $tar-red1;
      width: 100%;
      text-align: center;
    }
  }
}
