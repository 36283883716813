@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

.jp-request-interview-modal {
  padding: 1rem;
  letter-spacing: 0.06rem;
  width: 720px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  .header {
    @include flex-row-space-between;
    align-items: center;
    margin: 0.2rem 1rem 0.6rem 1rem;
  }
  .header-title {
    font-size: 1.6rem !important;
    font-weight: 600;
  }
  .header-icon {
    margin: 0;
  }
  .jobs-list {
    @include flex-col-space-between;
    margin: 0 1rem;

    .jos-list-header {
      width: 100%;
      @include flex-row-space-between;
      margin-top: 1rem;
      align-items: center;
      padding: 0 1rem;
      .header-name {
        font-weight: 600;
      }
    }

    .job-item {
      width: 100%;
      @include flex-row-space-between;
      align-items: center;
      margin-top: 0.8rem;
      padding: 0.2rem 1rem;
      border-bottom: solid 1px $tar-green2;

      .name {
        font-size: 0.9rem;
      }

      .check-label {
        transform: scale(1.2);
        margin-bottom: 0.2rem;
      }
    }

    .select-error {
      color: red;
      margin-top: 1rem;
      font-size: 0.9rem;
      width: 100%;
      text-align: center;
    }
  }
  .footer {
    margin-top: 2rem;
    @include flex-row-center;

    .submit {
      background-color: $tar-green1;
      color: white;
      font-weight: 500;
      font-size: 1rem;
    }
  }
}
