@import '@duik/core/_vars.scss';
@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

.full-resume-modal {
  padding: 1rem;
  letter-spacing: 0.06rem;
  width: 1080px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  .header {
    @include flex-row-space-between;
    align-items: center;
    margin: 0.2rem 1rem 1rem 2rem;
    .header-title {
      font-size: 1.8rem !important;
      font-weight: 600;
    }
    .header-icon {
      margin: 0;
    }
    .header-info {
      margin: 0;
      padding-top: 0.4rem;
      & > div {
        font-size: 0.9rem;
        line-height: 1.2rem;

        // margin: 0rem;
      }
    }
  }
  .content {
    .title-section {
      @include flex-row-space-between;
      margin: 1rem 0.6rem 1rem 2rem;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .titles {
        @include flex-col-space-between;
        width: 40%;
        .title {
          font-size: 1.1rem;
          font-weight: 500;
          margin: 0.4rem 0;
        }

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
      .desc-section {
        width: 50%;
        margin: 0;
        .desc-title {
          color: $tar-grey2;
          font-size: 0.8rem;
        }
        .description {
          font-size: 0.9rem;
          margin: 1rem 0;
        }

        @media screen and (max-width: 768px) {
          margin-top: 1rem;
          width: 100%;
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
    .years-section {
      @include flex-row-center;
      margin: 1.6rem 0;
      .years {
        font-size: 1.1rem;
        font-weight: 600;
      }

      & > .years + .years {
        margin-left: 4rem;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        .years {
          width: 100%;
          text-align: center;
        }

        & > .years + .years {
          margin-left: 0;
          margin-top: 1rem;
        }
      }
    }
    .props-section {
      margin: 2rem 2rem 1rem;
      text-align: center;
      @include flex-row-space-around;
      flex-wrap: wrap;

      .props-column {
        width: 30%;
        &-title {
          font-weight: 600;
          margin-bottom: 0.6rem;
        }
        &-text {
          font-size: 0.8rem;
          margin: 0.2rem;
        }
      }

      @media screen and (max-width: 992px) {
        .props-column {
          width: 48%;
        }

        .props-column:last-child {
          margin-top: 1.6rem;
        }
      }

      @media screen and (max-width: 576px) {
        flex-direction: column;
        .props-column {
          width: 100%;
        }

        & > .props-column + .props-column {
          margin-top: 1.6rem;
        }
      }
    }
    .details-section {
      margin: 2rem 2rem 4rem 2rem;
      &-row {
        margin: 1rem 0;
      }
      &-title {
        color: $text-main;
        font-weight: 600;
        font-size: 1.1rem;
      }
    }
  }
  .footer {
    @include flex-row-center;
    margin-bottom: 2rem;
    & > button {
      color: white;
    }

    & > button + button {
      margin-left: 1rem;
    }

    .update {
      background-color: $tar-green1;
    }
    .builder {
      background-color: $tar-blue1;
    }
    .view {
      background-color: $tar-yellow1;
    }

    @media screen and (max-width: 576px) {
      flex-direction: column;
      width: 100%;

      & > button + button {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }
}
