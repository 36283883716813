@import '@duik/core/_vars.scss';
@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

.js-references-wizard-modal {
  padding: 4rem 1.2rem 4rem 1.6rem;
  letter-spacing: 0.02rem;
  width: 920px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  .header {
    @include flex-row-space-between;
    align-items: center;
    margin: 0rem 0.6rem 0.6rem 0.6rem;

    .header-title {
      font-size: 1.4rem !important;
      font-weight: 600;
    }
    .header-right {
      margin: 0;
      @include flex-row-space-between;
      align-items: center;
    }
    .close-icon {
      margin: 0;
      margin-left: 1rem;
    }
  }

  .content {
    margin: 1rem;

    .add {
      margin: 0 auto;
      background-color: $tar-green1;
      color: white;
    }
  }

  .footer {
    @include flex-row-center;
    margin-top: 1rem;

    button {
      color: white;
    }

    & > button + button {
      margin-left: 2rem;
    }
    .prev {
      background-color: $blue;
    }
    .next {
      background-color: $tar-green1;
    }
    .cancel {
      background-color: $tar-yellow1;
    }

    @media screen and (max-width: 576px) {
      flex-direction: column;

      & > button + button {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }
}
