@import '@duik/core/_vars.scss';
@import '../../../../../assets/scss/variables.scss';
@import '../../../../../assets/scss/mixins.scss';

.employer-details {
  margin: 1rem 0;
  padding: 0.6rem 1rem;
  border: 1px solid $border-color-highlight;
  border-radius: 0.2rem;

  .wrapper {
    @include flex-row-space-between;
    width: 100%;
    margin: 0;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .left-column {
    width: 62%;
    @include flex-col;

    & > div + div {
      margin-top: 0.6rem;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .right-column {
    margin-left: 3%;
    width: 35%;
    @include flex-col;

    & > div + div {
      margin-top: 0.6rem;
    }

    .buttons {
      width: 100%;
      flex-grow: 1;
      @include flex-row-center;
      align-items: flex-end;
      margin-top: 2rem;

      button {
        margin-bottom: 1rem;
      }

      & > button + button {
        margin-left: 1rem;
      }

      .update {
        background-color: $cyan;
        color: white;
      }
      .remove {
        background-color: $red;
        color: white;
      }

      @media screen and (max-width: 600px) {
        flex-direction: column;

        & > button + button {
          margin-left: 0;
          margin-top: 1rem;
        }
      }
    }

    @media screen and (max-width: 768px) {
      margin-left: 0;
      width: 100%;
    }
  }
  .left-row1 {
    @include flex-row-space-between;
    & > div {
      width: 47%;
    }
    & > div + div {
      margin-left: 6%;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      & > div {
        width: 100%;
      }
      & > div + div {
        margin-left: 0;
      }
    }
  }
  .left-row2 {
    width: 100%;
    .duty-btn {
      margin-top: 0;
      color: $tar-green1;
    }

    .items-list {
      width: 100%;
      @include flex-col-space-between;
      align-items: flex-start;
      .item {
        @include flex-row-space-between;
        margin: 0.3rem 0;
        width: 100%;

        .item-label {
          width: 90%;
          font-size: 0.8rem;
          overflow-wrap: break-word;
          word-wrap: break-word;
        }
        .item-btn {
          width: 6%;
          cursor: pointer;
          margin-left: 1rem;
          font-size: 1.2rem;
          line-height: 1.2rem;
        }
      }
    }

    .select-error {
      color: red;
      margin: 0.3rem 0;
      font-size: 0.9rem;
    }
  }
  .right-row1 {
    .label {
      font-size: 0.7rem;
      font-weight: 500;
      color: $text-secondary;
      line-height: 1.125rem;
      margin: 0.6rem 0 0 0;
      text-transform: uppercase;
    }
    .inputs {
      display: flex;
      & > div {
        flex-grow: 1;
      }
      & > div + div {
        margin-left: 1rem;
        margin-top: 0;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        & > div + div {
          margin-left: 0;
          margin-top: 1rem;
        }
      }
    }
  }
  .right-row2 {
    .label {
      font-size: 0.7rem;
      font-weight: 500;
      color: $text-secondary;
      line-height: 1.125rem;
      margin: 0.6rem 0 0 0;
      text-transform: uppercase;
    }
    .inputs {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      & > div {
        width: 48%;
        margin-top: 0;
      }
      & > div:nth-child(2n) {
        margin-left: 4%;
      }
      & > div:nth-child(n + 3) {
        margin-top: 1rem;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        & > div {
          width: 100%;
        }

        & > div:nth-child(2n) {
          margin-left: 0;
          margin-top: 1rem;
        }
        & > div:nth-child(n + 3) {
          margin-top: 1rem;
        }
      }
    }
  }
  .select-error {
    color: red;
    margin: 0.3rem 0;
    font-size: 0.9rem;
  }
}
