@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

.jp-close-job-confirm {
  padding: 0.4rem 1rem 1rem;
  letter-spacing: 0.06rem;
  width: 100%;

  .header {
    @include flex-row-space-between;
    align-items: center;
    margin: 0.6rem 1rem 0.6rem 2rem;

    .header-title {
      font-size: 1.2rem !important;
      font-weight: 600;
    }

    .header-icon {
      margin: 0;
    }
  }

  .content {
    margin: 1rem 0;
    &-title {
      text-align: center;
      font-size: 1.1rem;
      font-weight: 500;
      color: $tar-grey4;
    }
    &-buttons {
      @include flex-row-center;
      margin-top: 1.6rem;

      button {
        width: 6rem;
        color: white;
        font-size: 1rem;
        font-weight: 500;
      }

      .ok {
        background-color: $tar-yellow1;
      }

      .cancel {
        margin-left: 2rem;
        background-color: $tar-green1;
      }
    }
  }
}
