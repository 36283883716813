$tar-red1: #ff0033;
$tar-red2: #be2a0f;

$tar-grey1: #080808;
$tar-grey2: #a9a9a9;
$tar-grey3: #d3d3d3;
$tar-grey4: #4b4b4b;

$tar-green1: #08405c;
$tar-green2: #5e8396;
$tar-green3: #08405c;
$tar-green4: #2c974b;

$tar-yellow1: #fa7714;

$tar-blue1: #0f5ac7;

$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 480px;
