@import '@duik/core/_vars.scss';
@import '../../../../../assets/scss/variables.scss';
@import '../../../../../assets/scss/mixins.scss';

.education-details {
  margin: 1rem 0 2rem 0;
  padding: 0.6rem 1rem;
  border: 1px solid $border-color-highlight;
  border-radius: 0.2rem;

  .section1 {
    @include flex-row-wrap;
    justify-content: space-between;

    & > div {
      width: 30%;
    }

    .location {
      .label {
        @include input-group-label;
        margin-bottom: 0.6rem;
      }
      .inputs {
        @include flex-row-space-between;

        & > div {
          margin: 0;
        }

        & > div + div {
          margin-left: 1rem;
        }

        @media screen and (max-width: 576px) {
          flex-direction: column;
          & > div + div {
            margin-left: 0;
            margin-top: 1rem;
          }
        }
      }
    }

    @media screen and (max-width: 1200px) {
      & > div {
        width: 48%;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
  }

  .section2 {
    margin-top: 1rem;
    @include flex-row-space-between;
    &-right {
      width: 65%;
      button {
        margin-top: 0;
        color: $tar-green1;
      }

      .items-list {
        width: 100%;
        @include flex-col-space-between;
        align-items: flex-start;
        .item {
          @include flex-row-space-between;
          margin: 0.3rem 0;
          width: 100%;

          .item-label {
            font-size: 0.8rem;
            width: 90%;
            overflow-wrap: break-word;
            word-wrap: break-word;
          }
          .item-btn {
            width: 6%;
            cursor: pointer;
            margin-left: 1rem;
            font-size: 1.2rem;
            line-height: 1.2rem;
          }
        }
      }

      .select-error {
        color: red;
        margin: 0.3rem 0;
        font-size: 0.9rem;
      }

      @media screen and (max-width: 1200px) {
        width: 100%;
      }
    }
    &-left {
      width: 30%;
      .label {
        @include input-group-label;
        margin-bottom: 0.6rem;
      }
      .inputs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        & > div {
          width: 48%;
          margin-top: 0;
        }
        & > div:nth-child(2n) {
          margin-left: 4%;
        }
        & > div:nth-child(n + 3) {
          margin-top: 1rem;
        }

        @media screen and (max-width: 576px) {
          flex-direction: column;

          & > div {
            width: 100%;
          }
          & > div:nth-child(2n) {
            margin-top: 1rem;
            margin-left: 0%;
          }
          & > div:nth-child(n + 3) {
            margin-top: 1rem;
          }
        }
      }
      .buttons {
        width: 100%;
        @include flex-row-center;
        margin-top: 2rem;

        & > button + button {
          margin-left: 1rem;
        }

        .update {
          background-color: $cyan;
          color: white;
        }
        .remove {
          background-color: $red;
          color: white;
        }

        @media screen and (max-width: 576px) {
          flex-direction: column;

          & > button + button {
            margin-left: 0;
            margin-top: 1rem;
          }
        }
      }

      @media screen and (max-width: 1200px) {
        width: 100%;
      }

      @media screen and (max-width: 576px) {
      }
    }

    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }
}
