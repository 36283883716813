@import '@duik/core/_vars.scss';
@import '../../../../../assets/scss/mixins.scss';

.reference {
  @include flex-row-space-between;
  margin-top: 1rem;
  margin-bottom: 0.6rem;
  font-size: 0.8rem;
  &-column {
    width: 30%;
    .item {
      color: $text-secondary;
      margin-bottom: 0.6rem;
      span {
        color: $text-main;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    &-column {
      width: 100%;
    }
  }
}
