@import '@duik/core/_vars.scss';
@import '../../../../../assets/scss/mixins.scss';

.work-history-content {
  margin: 1rem 0 2rem 0rem;
  color: $text-main;
  @include flex-row-space-between;

  .work-history-column1 {
    width: 32%;
    div {
      margin-bottom: 0.8rem;
      letter-spacing: 0.02rem;
      font-size: 0.8rem;
      overflow-wrap: break-word;
      word-wrap: break-word;
      span {
        color: $text-secondary;
      }
    }
  }

  .work-history-column2 {
    width: 64%;
    font-size: 0.8rem;

    & > div {
      margin-bottom: 0.3rem;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }

    & > div:first-child {
      color: $text-secondary;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    .work-history-column1 {
      width: 100%;
    }
    .work-history-column2 {
      width: 100%;
      margin-top: 0rem;
    }
  }
}
