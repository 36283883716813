//
// react select
//

$gray-100: #404954;
$gray-200: #37404a;
$gray-300: #464f5b;
$gray-400: #8391a2;
$gray-500: #aab8c5;
$gray-600: #ced4da;
$gray-700: #dee2e6;
$gray-800: #e3eaef;
$gray-900: #f1f1f1;
$blue: #3688fc;
$headings-color: #fff;
$body-color: $gray-400;
$dropdown-bg: #fff;
$zindex-dropdown: 1px;
$dropdown-border-width: 1px;
$dropdown-border-radius: 3px;
$dropdown-border-color: lighten($gray-600, 3%);
$dropdown-item-padding-x: 0.375rem;
$dropdown-item-padding-y: 0.375rem;
$dropdown-link-hover-color: $headings-color;
$dropdown-link-hover-bg: lighten($gray-300, 3%);

$input-border-width: 1px;
$input-border-color: lighten($gray-600, 3%);
$input-bg: #fff;
$primary: $blue;
$input-focus-bg: lighten($input-bg, 2.5%);
$input-focus-border-color: lighten($input-border-color, 5%);
$dropdown-link-color: $body-color;

.react-select {
  font-size: 0.875rem;
  letter-spacing: normal;

  > div {
    border: $input-border-width solid $input-border-color;
    background-color: $input-bg !important;

    &:hover {
      border: $input-border-width solid $input-border-color;
    }
  }
}

.rbt-token {
  background-color: rgba($primary, 0.2) !important;
  color: $primary !important;
}

.rbt-input-multi.focus {
  box-shadow: none !important;
  background-color: $input-focus-bg !important;
  border-color: $input-focus-border-color !important;
}

.react-select__control--is-focused {
  border-color: $input-focus-border-color !important;
  box-shadow: none !important;
}

.react-select__indicator-separator {
  display: none !important;
}

.react-select__dropdown-indicator {
  color: $gray-600 !important;
}

.react-select__menu {
  background-color: $dropdown-bg !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: $dropdown-border-radius !important;
  z-index: $zindex-dropdown !important;
}

.react-select__menu-list {
  border: $dropdown-border-width solid $dropdown-border-color !important;
  border-radius: $dropdown-border-radius !important;
}

.react-select__option {
  padding: $dropdown-item-padding-y $dropdown-item-padding-x !important;
  color: $dropdown-link-color !important;

  &:hover {
    color: $dropdown-link-hover-color !important;
    background-color: $gray-400 !important;
  }
}

.react-select__option--is-focused {
  color: $dropdown-link-hover-color !important;
  background-color: $gray-400 !important;
}

.react-select__option--is-selected {
  color: $dropdown-link-hover-color !important;
  background-color: $dropdown-link-hover-bg !important;
}

.react-select__multi-value {
  background-color: rgba($primary, 0.2) !important;
  color: $primary !important;

  .react-select__multi-value__label {
    color: $primary;
  }

  .react-select__multi-value__remove {
    &:hover {
      background-color: rgba($primary, 0.2) !important;
      color: $primary !important;
    }
  }
}
