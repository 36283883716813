@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

.js-job-search-modal {
  padding: 1rem;
  letter-spacing: 0.06rem;
  width: 800px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  .header {
    @include flex-row-space-between;
    align-items: center;
    margin: 0.6rem 1rem;
  }
  .header-title {
    font-size: 2rem !important;
    font-weight: 600;
  }
  .header-icon {
    margin: 0;
  }
  .content {
    .title-section {
      @include flex-row-space-between;
      margin: 1rem 2rem;
      .titles {
        @include flex-col-space-between;
        margin-right: 1rem;
        .title {
          font-size: 1.2rem;
          font-weight: 500;
        }
      }
      .avatar {
        @include flex-center;
        margin-right: 4rem;
        border-radius: 4rem;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        .titles {
          width: 100%;
        }

        .avatar {
          width: 100%;
          margin-top: 1rem;
        }
      }
    }
    .desc-section {
      margin: 2rem;
      .desc-title {
        color: $tar-grey2;
        font-size: 0.8rem;
      }
      .description {
        text-align: center;
        font-size: 0.9rem;
        margin: 1rem 0;
      }
    }
    .years-section {
      margin: 1rem 3rem;
      @include flex-row-space-between;
      .years {
        font-size: 1.1rem;
        font-weight: 600;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        & > div + div {
          margin-top: 1rem;
        }

        .years {
          text-align: center;
        }
      }
    }
    .props-section {
      margin: 2rem 0;
      @include flex-row-space-around;
      .column {
        flex: 1;
        text-align: center;
        .column-title {
          font-weight: 600;
          margin-bottom: 0.6rem;
        }
        .column-text {
          font-size: 0.8rem;
          margin: 0.2rem;
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        & > .column + .column {
          margin-top: 1rem;
        }
      }
    }
  }
  .footer {
    .buttons {
      width: 100%;
      @include flex-row-center;
      margin-bottom: 1rem;
      & > button {
        margin: 0 1rem;
        color: white;
      }
      .apply {
        background-color: $tar-green1;
      }
      .decline {
        background-color: $tar-red2;
      }
      .view {
        background-color: $tar-yellow1;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        & > button + button {
          margin-top: 1rem;
        }
      }
    }
    .status {
      display: flex;
      align-items: center;
      text-transform: capitalize;
      margin-right: 1rem;
      font-weight: 500;
    }
    .applied {
      color: $tar-blue1;
    }
    .hired {
      color: $tar-green4;
    }
    .declined {
      color: $tar-yellow1;
    }
    .rejected {
      color: $tar-red1;
    }
    .apply-error {
      font-size: 0.875rem;
      font-weight: 400;
      color: $tar-red1;
      width: 100%;
      text-align: center;
    }
  }
}
