@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@mixin flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@mixin flex-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@mixin flex-row-space-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@mixin flex-col {
  display: flex;
  flex-direction: column;
}

@mixin flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin flex-col-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin flex-col-space-around {
  display: flex;
  flex-direction: column;
  align-items: space-around;
}

@mixin input-group-label {
  font-size: 0.7rem;
  font-weight: 500;
  color: $text-secondary;
  line-height: 1.125rem;
  text-transform: uppercase;
}
