@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

.jp-create-job-modal {
  padding: 1rem;
  letter-spacing: 0.02rem;
  width: 920px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  .header {
    @include flex-row-space-between;
    align-items: center;
    margin: 0rem 0.2rem 0rem 1.6rem;

    .header-title {
      font-size: 1.3rem !important;
      font-weight: 500;
    }
    .tab-item {
      font-size: 1.3rem !important;
      font-weight: 500;
      height: 3rem;
    }
    .header-right {
      @include flex-row-space-between;
      align-items: center;
      margin: 0.4rem 0;
    }
    .close-icon {
      margin: 0 0 0 1rem;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .content {
    .title-section {
      @include flex-row-space-between;
      margin: 1rem 2rem;
      & > div {
        flex: 1;
      }
      & > div + div {
        margin-left: 2rem;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        & > div + div {
          margin-left: 0;
        }
      }

      .left-column {
        & > div {
          margin-top: 0.3rem;
        }

        .row {
          @include flex-row-space-between;

          & > div {
            margin-top: 0.3rem;
            width: 48%;
          }

          @media screen and (max-width: 768px) {
            flex-direction: column;
            & > div {
              width: 100%;
            }
          }
        }
      }
      .right-column {
        width: 100%;

        .summary {
          height: 300px;

          @media screen and (max-width: 768px) {
            height: 120px;
          }
        }
      }
    }
    .props-section {
      margin: 2rem 1rem;
      @include flex-row-space-around;
      & > div + div {
        // margin-left: 1.6rem;
        margin-left: 3%;
      }

      .title {
        font-weight: 500;
        font-size: 0.9rem !important;
        margin: 0.4rem 0;
      }

      .column {
        // flex: 1;
        width: 32%;
        display: flex;
        flex-direction: column;
        .column-title {
          font-weight: 600;
          font-size: 0.9rem;
          margin-bottom: 0.6rem;
        }

        .column-text {
          margin: 0.1rem;
          span {
            font-size: 0.8rem !important;
          }
        }

        .salary-tabs {
          @include flex-row-space-between;
          .salary-tabs-item {
            width: 50% !important;
            height: 1.8rem;

            &.active {
              background-color: $tar-green1;
              color: white;
            }

            @media screen and (max-width: 1200px) {
              font-size: 10px !important;
            }
          }
        }

        .salary-tabs-content {
          .range-inputs {
            .group {
              @include flex-row-space-between;
              align-items: center;

              .label {
                font-size: 0.9rem;
                width: 12%;
              }

              & > div + div {
                flex-grow: 1;
                margin-left: 1rem;
              }
            }

            & > div {
              margin-top: 0;
            }
          }
        }

        .add-btn {
          color: $tar-green1;
          font-size: 0.8rem;
          height: 1rem;
          margin: 0.6rem 0;
        }

        .check-label {
          margin: 0.2rem 0 !important;
          span {
            font-size: 0.8rem !important;
          }
        }

        .items-list {
          width: 100%;
          @include flex-col-space-between;
          align-items: flex-start;
          .item {
            width: 100%;
            @include flex-row-space-between;
            margin: 0.3rem 0;
            .item-label {
              width: 80%;
              font-size: 0.8rem;
              overflow-wrap: break-word;
              word-wrap: break-word;
            }
            .item-btn {
              width: 16%;
              cursor: pointer;
              font-size: 1.2rem;
              line-height: 1.2rem;
            }
          }
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        & > div + div {
          // margin-left: 1.6rem;
          margin-left: 0%;
        }

        .column {
          width: 100%;
        }
      }
    }
  }

  .select-error {
    color: red;
    margin: 0.3rem 0;
    font-size: 0.9rem;
  }

  .footer {
    @include flex-row-center;
    margin-bottom: 1rem;

    & > button {
      margin: 0 1rem;
      color: white;
    }
    .apply {
      background-color: $tar-green1;
    }
    .decline {
      background-color: $tar-red2;
    }
    .view {
      background-color: $tar-yellow1;
    }
  }

  .candidates {
    padding: 2rem 1rem;
    width: 100%;

    // .card-table {
    //   width: 720px;
    //   overflow-x: scroll;
    // }

    td {
      .view-btn + .view-btn {
        margin-left: 1rem;
      }

      i {
        margin-right: 1rem;
      }

      @media screen and (max-width: 768px) {
        i {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        .btn-text {
          display: none;
        }
      }

      @media screen and (max-width: 485px) {
        .view-btn + .view-btn {
          margin-left: 0rem;
          margin-top: 1rem;
        }
      }
    }
  }
}
