@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

.jp-admin-modal {
  padding: 0.4rem 1rem 1rem;
  letter-spacing: 0.06rem;
  width: 860px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  .header {
    @include flex-row-space-between;
    align-items: center;
    margin: 0.6rem 1rem 0.6rem 2rem;

    .header-title {
      font-size: 1.4rem !important;
      font-weight: 600;
    }
  }
  .header-icon {
    margin: 0;
  }
  .content {
    .title-section {
      @include flex-row-space-between;
      margin: 1rem 2rem;
      .titles {
        @include flex-col-space-between;
        width: 48%;
        .title {
          @include flex-row-space-between;
          & > div {
            margin: 0;
            flex: 1;
          }

          & > div + div {
            margin-left: 0.4rem;
          }

          @media screen and (max-width: 576px) {
            flex-direction: column;

            & > div + div {
              margin-left: 0rem;
            }
          }
        }
      }
      .avatar {
        width: 48%;
        @include flex-col-center;
        button {
          margin-top: 1rem;
        }
      }
      .icon {
        width: 30%;
        @include flex-center;
        margin-top: 2rem;
        margin-right: 4rem;
        width: 8rem;
        height: 8rem;
        border-radius: 4rem;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        .titles {
          width: 100%;
        }

        .avatar {
          width: 100%;
          margin-top: 2rem;
        }
      }
    }
    .fields-section {
      @include flex-col-space-between;
      margin: 0 2rem;
      & > div + div {
        margin-top: 1rem;
      }
      .field {
        @include flex-row-center;
        & > div {
          margin-top: 0;
        }
        & > div + div {
          margin-left: 2rem;
        }

        @media screen and (max-width: 576px) {
          flex-direction: column;

          & > div + div {
            margin-left: 0rem;
          }
        }
      }
    }
    .props-section {
      margin: 1.2rem;
      @include flex-row-space-around;

      & > div {
        margin-left: 2rem;
      }

      .column {
        flex: 1;
        @include flex-col-space-between;

        .title {
          font-weight: 500;
          margin-bottom: 0.6rem;
        }
        .column-text {
          font-size: 0.8rem;
          margin: 0.2rem;
        }
        .action-btn {
          background-color: $tar-green1;
          color: white;
        }

        @media screen and (max-width: 768px) {
          & > .action-btn + .action-btn {
            margin-top: 1rem;
          }
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-top: 2rem;

        & > div {
          margin: 0rem 2rem;
        }

        & > div + div {
          margin-top: 1rem;
        }
      }
    }
  }
  .select-error {
    color: red;
    margin: 0.3rem 0;
    font-size: 0.9rem;
  }
}
